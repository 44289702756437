<template>
  <b-media vertical-align="center">
    <b-card-header class="p-0">
      <h5>{{ data.start_date }} - {{ data.start_time }}</h5>

      <b-badge
        :variant="data.status.name == 'Confirmado' ? 'info' : 'warning'"
        text-color="white"
      >
        {{ data.status.name }}
      </b-badge>
    </b-card-header>
    <hr >
    <b-row>
      <b-col sm="12" md="6">
        <template>
          <b-avatar
            class="float-left mr-1"
            style="position: relative"
            :text="avatarText(data.professional.name)"
            :src="data.professional.image_profile"
          />
        </template>

        <b>{{ data.professional.name }}</b>
        <p class="text-muted mb-0">
          {{ data.specialty.name }}-CRM: {{ data.professional.crm_state }}/{{
            data.professional.crm_code
          }}
        </p>
      </b-col>
      <b-col sm="12" md="3">
        <b-badge
          class="ma-2"
          :variant="data.modality ? 'success' : 'danger'"
          text-color="white"
        >
          {{ data.modality === true ? 'Online' : 'Presencial' }}
        </b-badge>
      </b-col>
      <b-col sm="12" md="2" class="text-center">
        <b-button v-if="data.status.name === 'Confirmado'" class="btn-success">ACESSAR
        </b-button>
        <b-button v-if="data.status.name !== 'Confirmado'" variant="primary" @click="confirm(data.uuid)">CONFIRMAR
        </b-button>
      </b-col>
    </b-row>
  </b-media>
</template>

<script>
import { BAvatar, BMedia, BBadge } from 'bootstrap-vue'

export default {
  name: 'DsPatientGridCard',
  components: {
    BMedia,
    BAvatar,
    BBadge,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    avatarText(value) {
      if (!value) {
        return ''
      }
      const words = value.split(' ')
      const nameArray = [words[0]]
      if (words.length > 0) {
        nameArray.push(words[words.length - 1])
      }

      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
    confirm(uuid) {
      this.$router.push({ path: `/payment/${uuid}` })
    },
  },
}
</script>

<style scoped></style>
