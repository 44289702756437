import Vue from 'vue'

// axios
import axios from 'axios'

import store from '../store'

const actionScope = 'loader'

const axiosIns = axios.create({
  baseURL: `${process.env.VUE_APP_URL_API}/`,
  headers: { Accept: 'application/json' },
})
axiosIns.showLoader = true
let requestsPending = 0
const req = {
  pending: () => {
    requestsPending++

    if (axiosIns.showLoader) {
      store.dispatch(`${actionScope}/show`)
    }
  },
  done: () => {
    requestsPending--
    if (requestsPending <= 0) {
      if (axiosIns.showLoader) {
        store.dispatch(`${actionScope}/hide`)
      }
    }
  },
}

axiosIns.interceptors.request.use(
  request => {
    const authToken = sessionStorage.getItem('accessToken')
    if (authToken) {
      request.headers.Authorization = `Bearer ${authToken}`
    }
    request.headers.Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    req.pending()
    return request
  },
  error => {
    requestsPending--
    req.done()
    Promise.reject(error)
  },
)
axiosIns.interceptors.response.use(
  response => {
    req.done()
    if (response.status >= 200 && response.status < 300) {
      if (response.headers['content-type'] !== 'application/json') {
        return response.data
      }
      if (response.data.meta || response.data.error) {
        return response.data
      }

      if (typeof response.data.data === 'object') {
        return response.data.data
      }
      return response ? response.data : false
    }
    return false
  },
  error => {
    req.done()
    if (!error.response) {
      return {
        api_status: 0,
        api_statusText: 'response error',
        id: 0,
        data: [],
      }
    }

    const { status, data, statusText } = error.response
    // console.log(error.response)
    if (
      status === 401
      && !window.location.pathname.startsWith('/login')
      && !window.location.pathname.startsWith('/login/patient')
      && !window.location.pathname.startsWith('/login/appointment')
      && !window.location.pathname.startsWith('/login/professional')
      && window.location.pathname.startsWith('/')
      && !window.location.pathname.startsWith('/perdi-minha-senha')
      && !window.location.pathname.startsWith('/recuperar-senha')
      && !window.location.pathname.startsWith('/specialties')
      && !window.location.pathname.startsWith('/appointment/scheduling')
      && !window.location.pathname.startsWith('/appointment/schedule')
      && !window.location.pathname.startsWith('/register/patient')
      && !window.location.pathname.startsWith('/register/appointment')
      && !window.location.pathname.startsWith('/register/professional')
    ) {
      // sessionStorage.clear()
      // window.location.href = '/login'
      // return null
    }
    return {
      error: status,
      id: data.id,
      api_statusText: statusText,
      error_message: data.error_message || data.message,
      errors: data.errors,
      data: data.data,
    }
  },
)
Vue.prototype.$http = axiosIns
window.http = axiosIns
export default axiosIns
